import styles from './PlanView.module.css'
import type { Gender, Plan } from '../service/service'
import PlanRoutineView from './PlanRoutineView'

export default function PlanView(props: { className?: string; plan: Plan; gender: Gender }) {
  const { plan, gender } = props

  return (
    <div className={`${styles.root} ${props.className ?? ''}`}>
      <span className={`${styles.title} ellipsis-text flex-shrink-0 font-weight-semibold`}>{plan.name}</span>
      <div className={styles.days}>
        {plan.routines.map((e, i) => (
          <PlanRoutineView key={i} planRoutine={e} gender={gender} />
        ))}
      </div>
    </div>
  )
}
