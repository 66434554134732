import { useEffect, useState } from 'react'

type Result<T> = readonly ['waiting'] | readonly ['skipped'] | readonly ['failed', any] | readonly ['success', T]

export function useFetch<T>(url: string, options?: { skip?: boolean }): Result<T> {
  const [result, setResult] = useState<Result<T>>(['waiting'])

  useEffect(() => {
    if (options?.skip) {
      setResult(['skipped'])
      return
    }

    let abortController = new AbortController()

    fetchBody<T>(url, abortController)
      .then((response) => {
        if (!abortController.signal.aborted) {
          setResult(['success', response])
        }
      })
      .catch((error) => {
        if (!abortController.signal.aborted) {
          setResult(['failed', error])
        }
      })

    return () => {
      abortController.abort()
    }
  }, [url, options?.skip])

  return result
}

async function fetchBody<T>(url: string, abortController: AbortController): Promise<T> {
  const response = await fetch(url, { signal: abortController.signal })

  if (response.status !== 200) {
    const cause = await response.text()
    throw new Error('Status:' + response.status, { cause: cause })
  }

  if (abortController.signal.aborted) {
    throw new Error('Aborted')
  }

  const body = await response.json()

  if (abortController.signal.aborted) {
    throw new Error('Aborted')
  }

  return body as T
}
