import styles from './Main.module.css'
import { useData } from '../service/service'
import PlanView from '../components/PlanView'
import RoutineView from '../components/RoutineView'
import WorkoutView from '../components/WorkoutView'
import { useTranslation } from 'react-i18next'
import StoreView from '../components/StoreView'
import Loading from '../components/Loading'
import check_icon from '../assets/check_icon.png'
import AutoSizeSpan from '../components/AutoSizeSpan'
import MeasurementToggle from '../components/MeasurementToggle'

export default function Main() {
  const { t, i18n } = useTranslation()
  const id = window.location.pathname.substring(1)
  const [status, data] = useData(id, i18n.resolvedLanguage ?? 'en')

  return (
    <div className={styles.root}>
      <MeasurementToggle className={styles.measurement_toggle}></MeasurementToggle>

      {(() => {
        if (id === '') {
          return <StoreView></StoreView>
        }
        switch (status) {
          case 'failed':
            console.error('error', data)
            return <></>
          case 'success':
            switch (data.type) {
              case 'plan':
                return <PlanView className={styles.content} plan={data.value} gender={data.gender} />
              case 'routine':
                return <RoutineView className={styles.content} routine={data.value} gender={data.gender} />
              case 'workout':
                return <WorkoutView className={styles.content} workout={data.value} gender={data.gender} />
              default:
                return <></>
            }
          case 'skipped':
            return <></>
          case 'waiting':
            return <Loading className={'flex-grow-1'}></Loading>
        }
      })()}

      {status === 'success' ? (
        <a className={styles.get_app} href={getAppLink()} target="_blank" rel="noopener noreferrer">
          <img alt="" src={check_icon} />
          <AutoSizeSpan cloneAllignment="center">
            {(() => {
              switch (data.type) {
                case 'plan':
                  return t('getThisPlanInTheApp')
                case 'routine':
                  return t('getThisRoutineInTheApp')
                case 'workout':
                  return t('getThisWorkoutInTheApp')
              }
            })()}
          </AutoSizeSpan>
        </a>
      ) : (
        <></>
      )}
    </div>
  )
}

function getAppLink() {
  const DOMAIN_MAP = JSON.parse(process.env.REACT_APP_APP_DOMAIN!)
  return `${window.location.protocol}//${DOMAIN_MAP[window.location.host]}${window.location.pathname}`
}
