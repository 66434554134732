import type { TFunction, i18n } from 'i18next'
import type { RoutineExercise, Workout, WorkoutExercise } from '../service/service'
import type { MeasurementSystem } from '../i18n'

export function getSetInfo(exercise: RoutineExercise | WorkoutExercise, t: TFunction, i18n: i18n, measurement: MeasurementSystem): string {
  const setInfos = exercise.sets.map((s) => {
    const parts: string[] = []

    if (s.rep > 0) {
      switch (exercise.additionalData.isRep) {
        case 1:
          parts.push(t('repsWithCount', { count: s.rep }))
          break
        case 2:
          parts.push(t('stepsWithCount', { count: s.rep }))
          break
      }
    }

    if (s.weightKg > 0) {
      switch (exercise.additionalData.isWeight) {
        case 1:
          parts.push(formatWeigth(s.weightKg, 'quarter', t, i18n, measurement))
          break
      }
    }

    if (s.timeSecond > 0) {
      switch (exercise.additionalData.isDuration) {
        case 1:
          parts.push(t('secsWithCount', { count: Math.round(s.timeSecond) }))
          break
        case 2:
          parts.push(t('minsWithCount', { count: Math.round(s.timeSecond / 60) }))
          break
      }
    }

    if (s.distMeter > 0) {
      switch (exercise.additionalData.isDistance) {
        case 1:
          parts.push(formatShortDistance(s.distMeter, t, i18n, measurement))
          break
        case 2:
          parts.push(formatLongDistance(s.distMeter, t, i18n, measurement))
          break
      }
    }

    return parts.join(' \u00D7 ')
  })

  const allSetsSame = setInfos.find((e) => e !== setInfos[0]) === undefined

  if (allSetsSame) {
    return [t('setsWithCount', { count: setInfos.length }), setInfos[0]].filter((e) => e !== '').join(' \u00D7 ')
  } else {
    return setInfos.join(', ')
  }
}

export function getTotalSets(workout: Workout): string {
  const totalSets: number = workout.exercises.reduce((prev, curr) => {
    return prev + curr.sets.length
  }, 0)

  return `${totalSets}`
}

export function getTotalVolume(workout: Workout, t: TFunction, i18n: i18n, measurement: MeasurementSystem): string {
  const totalKg: number = workout.exercises.reduce((prev, curr) => {
    return (
      prev +
      curr.sets.reduce((prevSet, currSet) => {
        return prevSet + currSet.weightKg * currSet.rep
      }, 0)
    )
  }, 0)

  return formatWeigth(totalKg, 0, t, i18n, measurement)
}

type RoundMode = 'quarter' | 0 | 1 | 2

function formatWeigth(kg: number, roundMode: RoundMode, t: TFunction, i18n: i18n, measurement: MeasurementSystem): string {
  switch (measurement) {
    case 'imperial':
      const lbValue = formatNumber(kg / 0.45359237, roundMode, i18n)
      return t('lbWithValue', { value: lbValue })
    case 'metric':
      const kgValue = formatNumber(kg, roundMode, i18n)
      return t('kgWithValue', { value: kgValue })
  }
}

function formatNumber(value: number, roundMode: RoundMode, i18n: i18n): string {
  switch (roundMode) {
    case 'quarter':
      const roundedValue25 = roundToNearesMultiple(value, 0.25)
      const roundedValue50 = roundToNearesMultiple(value, 0.5)
      const roundedValue100 = roundToNearesMultiple(value, 1)
      if (roundedValue100 === roundedValue25) {
        return formatNumber(roundedValue100, 0, i18n)
      } else if (roundedValue50 === roundedValue25) {
        return formatNumber(roundedValue50, 1, i18n)
      } else {
        return formatNumber(roundedValue25, 2, i18n)
      }
    default:
      return new Intl.NumberFormat(i18n.resolvedLanguage ?? 'en', { maximumFractionDigits: roundMode, minimumFractionDigits: roundMode }).format(value)
  }
}

function roundToNearesMultiple(x: number, by: number): number {
  let floorIndex = Math.floor(x / by)
  let floorValue = floorIndex * by
  let ceilIndex = floorIndex + 1
  let ceilValue = ceilIndex * by
  let floorDiff = Math.abs(floorValue - x)
  let ceilDiff = Math.abs(ceilValue - x)
  if (floorDiff < ceilDiff) {
    return floorValue
  } else {
    return ceilValue
  }
}

function formatShortDistance(m: number, t: TFunction, i18n: i18n, measurement: MeasurementSystem): string {
  switch (measurement) {
    case 'imperial':
      const ftValue = formatNumber(m / 0.3048, 1, i18n)
      return t('ftWithValue', { value: ftValue })
    case 'metric':
      const mValue = formatNumber(m, 1, i18n)
      return t('mWithValue', { value: mValue })
  }
}

function formatLongDistance(m: number, t: TFunction, i18n: i18n, measurement: MeasurementSystem): string {
  switch (measurement) {
    case 'imperial':
      const miValue = formatNumber(m / 1609.344, 1, i18n)
      return t('miWithValue', { value: miValue })
    case 'metric':
      const kmValue = formatNumber(m / 1000, 1, i18n)
      return t('kmWithValue', { value: kmValue })
  }
}

export function getMusclesOfExercises(exercises: (RoutineExercise | WorkoutExercise)[], wearGreaterThan = 0): string[] {
  const muscles: { [muscle: string]: number } = {}
  for (const exercise of exercises) {
    for (const muscle in exercise.additionalData.wears) {
      const wear = exercise.additionalData.wears[muscle]
      muscles[muscle] = Math.min(1, (muscles[muscle] ?? 0) + wear)
    }
  }
  let a = Object.keys(muscles)
    .map((k) => [k, muscles[k]] as const)
    .filter((k) => k[1] > wearGreaterThan)
    .sorted((x, y) => y[1] - x[1])
    .map((e) => e[0])

  return a
}
