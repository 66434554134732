import styles from './WorkoutView.module.css'
import type { Gender, Workout } from '../service/service'
import { getMusclesOfExercises, getTotalSets, getTotalVolume } from '../utils/exercise-utils'
import { useTranslation } from 'react-i18next'
import ExerciseView from './ExerciseView'
import { useMemo } from 'react'
import MusclesView from './MusclesView'
import { useMeasurement } from '../i18n'

export default function WorkoutView(props: { className?: string; workout: Workout; gender: Gender }) {
  const { t, i18n } = useTranslation()
  const { workout, gender } = props
  const [measurementSystem] = useMeasurement()

  const muscles = useMemo(() => {
    return getMusclesOfExercises(workout.exercises)
  }, [workout])

  return (
    <div className={`${styles.root} ${props.className ?? ''}`}>
      <span className={`ellipsis-text text-align-center font-size-x-large flex-shrink-0 font-weight-semibold`}>{workout.name}</span>

      <div className={styles.infos}>
        <div className={styles.info}>
          <span className={styles.info_value}>{t('minsWithCount', { count: Math.round((workout.finishTimeMs - workout.startTimeMs) / 60000) })}</span>
          <span className={styles.info_label}>{t('duration')}</span>
        </div>
        <div className={styles.info}>
          <span className={styles.info_value}>{getTotalSets(workout)}</span>
          <span className={styles.info_label}>{t('sets')}</span>
        </div>
        <div className={styles.info}>
          <span className={styles.info_value}>{getTotalVolume(workout, t, i18n, measurementSystem)}</span>
          <span className={styles.info_label}>{t('volume')}</span>
        </div>
      </div>

      <div className="section">
        <span className={`color-text-secondary flex-shrink-0`}>{t('musclesWithCount', { count: muscles.length })}</span>
        <MusclesView muscles={muscles} gender={gender}></MusclesView>
      </div>

      <div className="section">
        <span className={`color-text-secondary flex-shrink-0`}>{t('exercisesWithCount', { count: workout.exercises.length })}</span>
        <div className={`${styles.exercises}`}>
          {workout.exercises.map((e, i) => (
            <ExerciseView key={i} exercise={e} gender={gender} measurementSystem={measurementSystem} />
          ))}
        </div>
      </div>
    </div>
  )
}
