import styles from './StoreView.module.css'
import { useMemo } from 'react'
import { getPlatform } from '../utils/platform-utils'
import { useTranslation } from 'react-i18next'
import app_logo_ios from './../assets/app_logo_ios.png'
import app_logo_android from './../assets/app_logo_android.png'

export default function StoreView() {
  const platform = getPlatform()
  const showGooglePlayLink = platform === 'android' || platform === 'unknown'
  const showAppStoreLink = platform === 'ios' || platform === 'unknown'

  const androidAppURL = useMemo(() => {
    let url = new URL('https://play.google.com/store/apps/details')
    url.searchParams.append('id', 'homeworkout.fitness.app')
    url.searchParams.append('utm_source', window.location.host)
    url.searchParams.append('utm_campaign', 'share_web')
    return url.toString()
  }, [])

  const iosAppURL = 'https://apps.apple.com/app/apple-store/id1665617526?pt=122493419&ct=share_web&mt=8'

  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()

  const appLogo = useMemo(() => {
    if (platform === 'ios') {
      return app_logo_ios
    } else {
      return app_logo_android
    }
  }, [platform])

  return (
    <div className={styles.root}>
      <img className={styles.app_logo} alt="" src={appLogo}></img>

      <span className={styles.description}>{t('description')}</span>

      <div className={styles.links}>
        {showGooglePlayLink ? (
          <div>
            <a href={androidAppURL}>
              <img alt={t('getItOnGooglePlay')} src={'locales/' + (resolvedLanguage ?? 'en') + '/google_play_badge.png'} />
            </a>
          </div>
        ) : (
          <></>
        )}
        {showAppStoreLink ? (
          <div>
            <a href={iosAppURL}>
              <img alt={t('getItOnAppStore')} src={'locales/' + (resolvedLanguage ?? 'en') + '/app_store_badge.svg'} />
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.legal_attribution}>
        {showGooglePlayLink ? <span>{t('legalAttributionGooglePlay')}</span> : <></>}
        {showAppStoreLink ? <span>{t('legalAttributionAppStore')}</span> : <></>}
      </div>
    </div>
  )
}
