type Platform = 'ios' | 'android' | 'unknown'

export function getPlatform(): Platform {
  let originalDomain = window.location.host
  let userAgent = window.navigator.userAgent
  if (userAgent?.includes('Android')) {
    return 'android'
  } else if (userAgent?.includes('iPhone')) {
    return 'ios'
  } else if (userAgent?.includes('iPad')) {
    return 'ios'
  } else if (originalDomain.includes('fitaiapp.com')) {
    return 'android'
  } else if (originalDomain.includes('workoutsapps.com')) {
    return 'ios'
  }
  return 'unknown'
}
