import styles from './PlanRoutineView.module.css'
import { useTranslation } from 'react-i18next'
import type { Gender, PlanRoutine } from '../service/service'
import { getMusclesOfExercises } from '../utils/exercise-utils'
import { useMemo } from 'react'
import MusclesView from './MusclesView'
import ExerciseImageView from './ExerciseImageView'

export default function PlanRoutineView(props: { planRoutine: PlanRoutine; gender: Gender }) {
  const { t } = useTranslation()
  const { gender, planRoutine } = props

  const muscles = useMemo(() => {
    return getMusclesOfExercises(planRoutine.routine?.exercises ?? [], 0.2)
  }, [planRoutine])

  return (
    <div className={styles.root}>
      <span>{t('dayIndex', { index: planRoutine.dayIndex + 1 })}</span>
      <span className={`ellipsis-text font-weight-bold`}>{planRoutine.routine?.name}</span>
      <MusclesView gender={gender} muscles={muscles}></MusclesView>
      <div className={styles.exercises}>{planRoutine.routine?.exercises.map((e, i) => <ExerciseImageView key={i} exercise={e.additionalData} />)}</div>
    </div>
  )
}
