import styles from './RoutineView.module.css'
import type { Gender, Routine } from '../service/service'
import { useTranslation } from 'react-i18next'
import ExerciseView from './ExerciseView'
import { useMeasurement } from '../i18n'

export default function RoutineView(props: { className?: string; routine: Routine; gender: Gender }) {
  const { routine, gender } = props
  const { t } = useTranslation()
  const [measurementSystem] = useMeasurement()

  return (
    <div className={`${styles.root} ${props.className ?? ''}`}>
      <span className={`ellipsis-text text-align-center font-size-x-large flex-shrink-0 font-weight-semibold`}>{routine.name}</span>
      <div className="section">
        <span className={`ellipsis-text font-size-larger flex-shrink-0`}>{t('exercisesWithCount', { count: routine.exercises.length })}</span>
        <div className={styles.exercises}>
          {routine.exercises.map((e, i) => (
            <ExerciseView key={i} exercise={e} gender={gender} measurementSystem={measurementSystem} />
          ))}
        </div>
      </div>
    </div>
  )
}
