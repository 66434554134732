import './extensions'
import './i18n'
import './index.css'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './pages/App'
import Main from './pages/Main'
import { useTranslation } from 'react-i18next'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<React.StrictMode>{<Root></Root>}</React.StrictMode>)

function Root() {
  const { i18n } = useTranslation()

  useEffect(() => {
    document
      .getElementsByTagName('html')
      .item(0)
      ?.setAttribute('dir', i18n.dir(i18n.resolvedLanguage ?? 'en'))
  }, [i18n, i18n.resolvedLanguage])

  return process.env.REACT_APP_TARGET === 'app' ? <App /> : <Main />
}
