import styles from './MeasurementToggle.module.css'
import { useTranslation } from 'react-i18next'
import { getMeasurementSystemTitle, MeasurementSystem_AllCases, useMeasurement } from '../i18n'

export default function MeasurementToggle(props: { className?: string }) {
  const { className } = props
  const { t } = useTranslation()
  const [measurementSystem, toggleMeasurement] = useMeasurement()

  return (
    <div className={`${className} ${styles.root}`}>
      {MeasurementSystem_AllCases.map((e) => {
        return (
          <button key={e} onClick={() => toggleMeasurement()} {...{ current: e === measurementSystem ? '' : undefined }}>
            {getMeasurementSystemTitle(e, t)}
          </button>
        )
      })}
    </div>
  )
}
