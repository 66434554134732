import styles from './AutoSizeSpan.module.css'
import { useState } from 'react'

export default function AutoSizeSpan(props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & { cloneAllignment?: 'bottom' | 'center' }) {
  const { cloneAllignment, className, style, ...spanProps } = props
  const [element, setElement] = useState<HTMLSpanElement | null>(null)

  const isEllipsisActive = element ? element.offsetWidth < element.scrollWidth : false
  const ratio = element ? element.offsetWidth / element.scrollWidth : 1
  const originalFontSize = element ? window.getComputedStyle(element).getPropertyValue('font-size') : undefined

  return (
    <div className={styles.root}>
      <span ref={setElement} {...spanProps} className={`${styles.span} ${className ?? ''}`} style={{ ...style, visibility: isEllipsisActive ? 'hidden' : undefined }} />
      {isEllipsisActive ? (
        <span
          {...spanProps}
          className={`${styles.span} ${styles.clone_span}  ${className ?? ''}`}
          style={{
            ...style,
            fontSize: originalFontSize ? `calc(${originalFontSize} * ${ratio * 0.99})` : undefined,
            bottom: cloneAllignment === 'center' ? 'auto' : 0,
            top: cloneAllignment === 'center' ? 'auto' : undefined,
          }}
        />
      ) : (
        <></>
      )}
    </div>
  )
}
