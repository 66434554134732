export type Equality<T> = (e1: T, e2: T) => boolean
export {}

declare global {
  export interface Array<T> {
    sorted(compareFn?: (a: T, b: T) => number): T[]
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.sorted = function <T>(compareFn?: (a: T, b: T) => number): T[] {
  const copy = [...this]
  copy.sort(compareFn)
  return copy
}
