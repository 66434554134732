import { useTranslation } from 'react-i18next'
import styles from './MuscleView.module.css'
import AutoSizeSpan from './AutoSizeSpan'

export default function MuscleView(props: { muscle: string; gender: string; mode: 'normal' | 'small' }) {
  const { t } = useTranslation()

  const { muscle, gender } = props
  return (
    <div className={styles.root} {...{ mode: props.mode }}>
      <AutoSizeSpan className={`${styles.title}`}>{t(`muscle${muscle}`)}</AutoSizeSpan>
      <img className={styles.image} src={`/muscles/${gender}/${muscle.toLowerCase()}.png`} alt={t(`muscle${muscle}`)}></img>
    </div>
  )
}
