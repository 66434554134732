import styles from './ExerciseView.module.css'
import { useTranslation } from 'react-i18next'
import type { Gender, RoutineExercise, WorkoutExercise } from '../service/service'
import ExerciseImageView from './ExerciseImageView'
import { getSetInfo } from '../utils/exercise-utils'
import MuscleView from './MuscleView'
import { useMemo } from 'react'
import type { MeasurementSystem } from '../i18n'

export default function ExerciseView(props: { exercise: RoutineExercise | WorkoutExercise; gender: Gender; measurementSystem: MeasurementSystem }) {
  const { exercise, gender, measurementSystem } = props
  const { t, i18n } = useTranslation()

  const muscle = useMemo(() => {
    let mostWeared: [string, number] | undefined = undefined
    for (const key in exercise.additionalData.wears) {
      const value = exercise.additionalData.wears[key]
      if (mostWeared === undefined || value > mostWeared[1]) {
        mostWeared = [key, value]
      }
    }
    return mostWeared![0]
  }, [exercise.additionalData.wears])

  const isWorkout = 'workoutId' in exercise

  return (
    <div className={styles.root}>
      <ExerciseImageView exercise={exercise.additionalData}></ExerciseImageView>
      <div className={styles.info}>
        <span className={`ellipsis-text ${isWorkout ? 'font-size-smaller color-text-secondary' : 'font-weight-semibold'}`}>{exercise.additionalData.name}</span>
        <span className="color-text-secondary font-size-smaller">{getSetInfo(exercise, t, i18n, measurementSystem)}</span>
      </div>
      {isWorkout ? <></> : <MuscleView muscle={muscle} gender={gender} mode={isWorkout ? 'normal' : 'small'}></MuscleView>}
    </div>
  )
}
