import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import Backend from 'i18next-http-backend'
import type { i18n as i18nT, TFunction } from 'i18next'
import { useCallback, useEffect, useState } from 'react'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: window.navigator.language,
    fallbackLng: [...window.navigator.languages, 'en'],
    nonExplicitSupportedLngs: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n

export type MeasurementSystem = 'imperial' | 'metric'
export const MeasurementSystem_AllCases: MeasurementSystem[] = ['imperial', 'metric']

export function getMeasurement(i18n: i18nT): MeasurementSystem {
  const cached = localStorage.getItem('measurement-system')
  if (cached === 'imperial' || cached === 'metric') {
    return cached
  }

  const languageParts = i18n.language.split('-')
  if (languageParts[1] === 'US') {
    //United States of America
    return 'imperial'
  }
  if (languageParts[1] === 'LR') {
    //Liberia
    return 'imperial'
  }
  if (languageParts[1] === 'MM') {
    //Myanmar
    return 'imperial'
  }
  return 'metric'
}

export function useMeasurement(): readonly [MeasurementSystem, () => void] {
  const { i18n } = useTranslation()
  const [measurementSystem, setMeasurementSystem] = useState(getMeasurement(i18n))

  useEffect(() => {
    const listener = () => {
      setMeasurementSystem(getMeasurement(i18n))
    }

    window.addEventListener('storage', listener, false)
    window.addEventListener('_storage', listener, false)

    return () => {
      console.log('fasdfasds')
      window.removeEventListener('storage', listener, false)
      window.removeEventListener('_storage', listener, false)
    }
  }, [i18n])

  const toggleCallback = useCallback(() => {
    localStorage.setItem('measurement-system', measurementSystem === 'metric' ? 'imperial' : 'metric')
    window.dispatchEvent(new Event('_storage'))
  }, [measurementSystem])

  return [measurementSystem, toggleCallback]
}

export function getMeasurementSystemTitle(measurementSystem: MeasurementSystem, t: TFunction): string {
  switch (measurementSystem) {
    case 'imperial':
      return t('lbUnit')
    case 'metric':
      return t('kgUnit')
  }
}
