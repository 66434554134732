import styles from './MusclesView.module.css'
import MuscleView from './MuscleView'
import type { Gender } from '../service/service'

export default function MusclesView(props: { muscles: string[]; gender: Gender }) {
  const { muscles, gender } = props
  return (
    <div className={styles.root}>
      {muscles.map((e) => (
        <MuscleView key={e} gender={gender} muscle={e} mode="normal"></MuscleView>
      ))}
    </div>
  )
}
